<template>
  <el-card shadow="never" class="aui-card--fill">
    <div class="mod-activiti__process">
      <el-form
        :inline="true"
        :model="dataForm"
        @keyup.enter.native="getDataList()"
      >
        <el-form-item>
          <el-input
            v-model="dataForm.processName"
            :placeholder="$t('process.name')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="dataForm.key"
            :placeholder="$t('process.key')"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="getDataList()">{{ $t('query') }}</el-button>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="dataList"
        border
        @selection-change="dataListSelectionChangeHandle"
        @sort-change="dataListSortChangeHandle"
        style="width: 100%"
      >
        <el-table-column
          type="selection"
          header-align="center"
          align="center"
          width="50"
        ></el-table-column>
        <el-table-column
          prop="id"
          :label="$t('process.id')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="deploymentId"
          :label="$t('process.deploymentId')"
          header-align="center"
          align="center"
          width="80"
        ></el-table-column>
        <el-table-column
          prop="name"
          :label="$t('process.name')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="key"
          :label="$t('process.key')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="version"
          :label="$t('process.version')"
          header-align="center"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="resourceName"
          :label="$t('process.resourceName')"
          header-align="center"
          align="center"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            <a
              :href="
                getResourceURL(scope.row.deploymentId, scope.row.resourceName)
              "
              target="_blank"
              >{{ scope.row.resourceName }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="diagramResourceName"
          :label="$t('process.diagramResourceName')"
          header-align="center"
          align="center"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            <a
              :href="
                getResourceURL(
                  scope.row.deploymentId,
                  scope.row.diagramResourceName
                )
              "
              target="_blank"
              >{{ scope.row.diagramResourceName }}</a
            >
          </template>
        </el-table-column>
        <el-table-column
          prop="deploymentTime"
          :label="$t('process.deploymentTime')"
          header-align="center"
          align="center"
          width="180"
        ></el-table-column>
        <el-table-column
          :label="$t('handle')"
          fixed="right"
          header-align="center"
          align="center"
          width="150"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="createProcessInstance(scope.row)"
              >{{ $t('process.createInstance') }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="limit"
        :total="total"
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="pageSizeChangeHandle"
        @current-change="pageCurrentChangeHandle"
      >
      </el-pagination>
      <add-or-update
        v-if="addOrUpdateVisible"
        ref="addOrUpdate"
        @refreshDataList="getDataList"
      ></add-or-update>
    </div>
  </el-card>
</template>

<script>
import mixinViewModule from '@/mixins/view-module';
import { addDynamicRoute } from '@/router';
import qs from 'qs';
export default {
  mixins: [mixinViewModule],
  data() {
    return {
      mixinViewModuleOptions: {
        getDataListURL: '/act/process/lastestPage',
        getDataListIsPage: true,
        deleteURL: '/act/process',
        deleteIsBatch: true,
        deleteIsBatchKey: 'deploymentId',
      },
      dataForm: {
        processName: '',
        key: '',
      },
      deployVisible: false,
    };
  },
  components: {},
  methods: {
    // 获取流程(xml/image)url地址
    getResourceURL(id, name) {
      var params = qs.stringify({
        token: sessionStorage.getItem('token'),
        deploymentId: id,
        resourceName: name,
      });
      return `${window.SITE_CONFIG['apiURL']}/act/process/resource?${params}`;
    },
    // 发起流程
    createProcessInstance(row) {
      this.$http
        .get(`/act/process/getProcDefBizRoute/${row.id}`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          if (!res.data || !res.data.bizRoute) {
            return this.$message.error(this.$t('process.routeError'));
          }
          this.forwardUrl(res.data, row);
        })
        .catch(() => {});
    },
    forwardUrl(data, row) {
      var routeParams = {
        routeName: `${this.$route.name}__instance_${data.id}`,
        menuId: `${this.$route.meta.menuId}`,
        title: `${this.$route.meta.title} - ${row.name}`,
        path: data.bizRoute,
        params: {
          processDefinitionId: data.procDefId,
          processDefinitionKey: row.key,
          processShowType: 'start',
        },
      };
      addDynamicRoute(routeParams, this.$router, this.$route);
    },
    // 启动其他流程
    startProcess(key) {
      var params = qs.stringify({
        key: key,
      });
      this.$http
        .post(`/act/running/start`, params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: this.$t('prompt.success'),
            type: 'success',
            duration: 500,
            onClose: () => {
              this.getDataList();
            },
          });
        })
        .catch(() => {});
    },
  },
};
</script>
